<template>
  <div class="info-card">
    <MyLink
      class="info-card__image"
      name="installation-work_article"
      :params="{
        installationArticleSlug: getLocalizedName(preview.url),
      }"
    >
      <img
        v-if="preview.img"
        :src="useNormalizeCtfUrl(getLocalizedName(preview.img.file)?.url)"
        :alt="getLocalizedName(preview.img.title)"
        loading="lazy"
      />
      <img v-else src="/img/photo-plug.svg" alt="banner" loading="lazy" />
    </MyLink>
    <div class="info-card__content">
      <div class="info-card__header">
        <BlogLabel :label="preview.category" />
        <time
          v-if="preview.created"
          :datetime="new Date(preview.created).toISOString()"
          class="info-card__date"
        >
          {{ getDate(preview.created) }}
        </time>
      </div>
      <MyLink
        class="info-card__title"
        name="installation-work_article"
        :params="{
          installationArticleSlug: getLocalizedName(preview.url),
        }"
      >
        {{ getLocalizedName(preview.name) }}
      </MyLink>
    </div>
  </div>
</template>

<script setup>
import BlogLabel from "~/modules/blog/components/BlogLabel.vue";

import { useNormalizeCtfUrl } from "~/utils/normalize";

const props = defineProps({
  preview: { type: Object, required: true },
});

const getDate = (date) =>
  useState("blog card date" + date, () =>
    new Intl.DateTimeFormat("uk-UA", {}).format(Date.parse(date)),
  );
</script>

<style lang="scss" scoped>
.info-card {
  background-color: white;
  border-radius: 16px;

  &__image {
    width: 100%;

    & > img {
      aspect-ratio: 16 / 9;
      object-fit: cover;
      border-radius: 16px;
    }
  }

  &__content {
    @include flex-container(column, flex-start);
    gap: 16px;

    padding: 16px 8px;

    @include bigMobile {
      gap: 8px;
    }
  }

  &__header {
    @include flex-container(row, space-between, center);
    gap: 8px;
  }

  &__date {
    @include font(14, 20);
    letter-spacing: 0.02em;
    color: var(--color-sky-darker);
  }

  &__title {
    @include font(20, 26, 600);

    transition: color 0.2s;

    &:hover {
      color: var(--color-primary-base);
    }

    @include bigMobile {
      @include font(16, 24, 600);
    }
  }
}
</style>
